<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>New PPE Request</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" squared @click="doNewPpeRequest()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner> Save</b-button>
                        </b-col>
                    </b-row>
                    <b-row v-if="state === 'initiliazing'">
                        <b-col class="text-center">
                            <b-spinner large></b-spinner>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col><b-alert variant="info" show>Please fill in the values that you need. Anything not filled in will not be part of the request.</b-alert></b-col>
                    </b-row>
                    <b-row v-if="state !== 'initiliazing'">
                        <b-col>
                            <b-table striped hover :items="tablePerishableData.dataSource" 
                                    :fields="tablePerishableData.tableColumns" 
                                    :busy="tablePerishableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                
                                <template #cell(size)="row">
                                    <b-form-input v-model="row.item.size"></b-form-input>
                                    <!-- <b-form-select v-model="row.item.size" :disabled="state === 'loading'">
                                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                                    </b-form-select> -->
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row v-if="state !== 'initiliazing'">
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                
                                <template #cell(size)="row">
                                    <b-form-input v-model="row.item.size"></b-form-input>
                                    <!-- <b-form-select v-model="row.item.size" :disabled="state === 'loading'">
                                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                                    </b-form-select> -->
                                </template>

                                
                                <template #cell(quantity)="row">
                                    <b-form-input v-model="row.item.quantity" type="number" :min="0"></b-form-input>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row v-if="state !== 'initiliazing'">
                        <b-button block @click="addMoreModalOpen()" variant="primary">Add more</b-button>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        
        <b-modal id="add-more-modal" hide-footer no-header @close="addMoreModalClose()">
            <b-row v-if="showModalError">
                <b-col>
                    <b-alert :show="true"
                         dismissible
                         variant="warning">
                        {{modalError}}
                    </b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Description</label>
                    <b-form-input v-model="addMore.description"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Quantity</label>
                    <b-form-input v-model="addMore.quantity" type="number" :min="0"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Size</label>
                    
                    <b-form-input v-model="addMore.size"></b-form-input>
                    <!-- <b-form-select v-model="addMore.size" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                    </b-form-select> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Image Upload</label>
                     <b-form-file
                        v-model="addMore.imageFile"
                        @change="filesUploaded"
                        placeholder="Choose a file..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" size="sm" squared @click="doAddMore()">
                        <b-spinner small v-if="isDocumentConverting === true"></b-spinner> Save</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data: () => ({
        state: 'initiliazing',
        addMore: {
            description: null,
            quantity: null,
            size: null,
            perishable: false,
            image: null
        },
        showModalError: false,
        modalError: '',
        isDocumentConverting: false,
        sizes: ['X-Small', 'Small', 'Medium', 'Large', 'X-Large', 'XX-Large'],
        tablePerishableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Size',
                    key: 'size',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Quantity',
                    key: 'quantity',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Size',
                    key: 'size',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'PPE Request'
            },
            {
                text: 'Create',
                active: true
            }
        ])
        this.getAvailableItems()
        .then((res) => {
            let perishable = []
            let nonreplenishable = []
            res.data.result.forEach(item => {
                if (item.perishable) {
                    perishable.push(item)
                }
                else {
                    nonreplenishable.push(item)
                }
            });
            this.tableData.dataSource = nonreplenishable
            this.tablePerishableData.dataSource = perishable
            this.tableData.isLoading = false
            this.tablePerishableData.isLoading = false
            this.state = 'show'
        })
        .catch(() => {
            this.tableData.isLoading = false
            this.state = 'show'
        })
    
    },
    methods:{
        ...mapActions(['getAvailableItems', 'createNewRequest', 'uploadAnswerImage']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        doAddMore(){
            if ((this.addMore.description === null || this.addMore.description === '') || (this.addMore.quantity === null || this.addMore.quantity === 0)) {
                this.modalError = 'You need to enter a description and quantity for this item'
                this.showModalError = true
                return
            }
            this.tableData.isLoading = true
            this.tableData.dataSource.push(this.addMore)
            this.tableData.isLoading = false
            this.addMoreModalClose()
            this.addMore = {
                description: null,
                quantity: null,
                perishable: false,
                size: null,
                image: null
            }
        },
        addMoreModalOpen(){
            this.showModalError = false
            this.$root.$emit('bv::show::modal', 'add-more-modal', '#btnShow')
        },
        addMoreModalClose(){
            this.$root.$emit('bv::hide::modal', 'add-more-modal', '#btnShow')
        },
        doNewPpeRequest(){
            this.state = 'loading'
            let requestList = this.tablePerishableData.dataSource.concat(this.tableData.dataSource)

            // for (let i = 0; i < requestList.length; i++) {
            //     const element = requestList[i];
            // }
            let request = {
                ppEitems: requestList
            }

            this.$store.commit('setNewPpeRequestRequest', request)
            this.createNewRequest()
            .then(() => {
                this.$router.back()
            })
            .catch(() => {
                this.state = 'showing'
            })
        },
        

        filesUploaded(e){
          const selectedDocument = e.target.files[0]
          if (selectedDocument.size > 20000000) {
            this.clearMessage()
            this.addErrorMessage({error: '',message: 'Document to big to be uploaded'})
            return
          }
          this.createBase64File(selectedDocument)
          this.uploadFile = null
          },
        createBase64File(file){
          this.isDocumentConverting = true
          const reader = new FileReader()
          
          let that = this 

          reader.onload = (e) => {
            let file1 = e.target.result

            // that.docUpload.base64Data = this.file1
            // that.docUpload.description = that.documentDetails.description
            // that.docUpload.date = new Date ()
            // that.docUpload.documentName = file.name
            // that.docUpload.ticketId = that.selectedJobCard.ticketId

            
                let request = {
                    documentName: file.name,
                    base64Data: file1,
                    description: file.name
                }

                this.$store.commit('setUploadAnswerImageRequest', request)
                this.uploadAnswerImage()
                .then((res) =>{
                    that.addMore.imageDocumentId = res.data.result
                    that.isDocumentConverting = false
                })
                .catch(() => {
                    that.isDocumentConverting = false
                })

          }
          reader.readAsDataURL(file)
      },
    },
    computed:{
    }
}
</script>
